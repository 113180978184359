$uib-color: $socraft-yellow;
$uib-size: 45px;
$uib-speed: 1500ms;
$uib-bg-opacity: 0;

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    height: $uib-size;
    width: $uib-size;
    transform-origin: center;
    animation: rotate $uib-speed linear infinite;
    will-change: transform;
    overflow: visible;

    .car {
      fill: none;
      stroke: $uib-color;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: stretch calc($uib-speed * 0.75) ease-in-out infinite;
      will-change: stroke-dasharray, stroke-dashoffset;
      transition: stroke 0.5s ease;
    }

    .track {
      fill: none;
      stroke: $uib-color;
      opacity: $uib-bg-opacity;
      transition: stroke 0.5s ease;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 75, 150;
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dashoffset: -100;
  }
}
