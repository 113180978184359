.crafters {
  display: flex;
  align-items: stretch;

  .crafter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    .avatar {
      height: 130px;
      width: 130px;
      border-radius: 15px 25px;
      transition:
        opacity 0.3s ease,
        transform 0.3s ease;
    }

    .infos {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      transition: opacity 0.3s ease;
    }

    &:hover {
      .avatar {
        opacity: 0.5;
        transform: scale(1.02);
      }

      .infos {
        opacity: 0.5;
      }
    }

    .firstname {
      font-weight: 600;
    }

    .job {
      text-align: center;
    }

    .open-to-work {
      color: green;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
