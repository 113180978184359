$socraft-dark: #282828;
$socraft-yellow: #fbbb10;
$default-page-background: #f7f6f2;
$white: #fff;
$success: #62bd19;
$danger: #ff0e0ed6;
$box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 3px 0px;
$border-color: #28282827;
$uib-size: 60px;
$uib-speed: 0.5s;
