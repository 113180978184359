@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import "./variables.scss";
@import "./components/crafters.scss";
@import "./components/loader.scss";

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  margin-block: 0;
}

svg:has(g) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

text {
  font-size: 12px;
  fill: #282828;
}
